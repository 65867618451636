import './style.css'
import rcompare from 'semver/functions/rcompare'
import path from 'path'

const current = require.context('.', true, /dummy\.js/).keys()[0]
const dir = path.dirname(current)
const schemas = require.context('.', true, /\.schema\.json$/).keys()
const tree = {}
const menu = document.getElementById('menu')
const app = document.getElementById('app')

function updatePreview (event) {
  app.setAttribute('src', window.location.origin + '/docson/index.html#/' + event.target.getAttribute('data-schema'))

  document.querySelectorAll('.active').forEach(el => {
    el.classList.remove('active')
  })

  event.target.classList.add('active')
}

function buildTree (tree, paths, acc = '') {
  if (typeof tree[paths[0]] === 'undefined') tree[paths[0]] = {}
  if (paths.length === 1) {
    tree[paths[0]] = acc + path.sep + paths[0]
  } else if (paths.length > 1) {
    buildTree(tree[paths[0]], paths.slice(1), acc + (!acc ? '' : path.sep) + paths[0])
  }
}

function treeToHtml (el, tree) {
  function _treeToHtml (ul, tree) {
    const filesOrPaths = Object.keys(tree).sort((a, b) => {
      if (typeof tree[a] === 'string' && typeof tree[b] !== 'string') return 1
      if (typeof tree[a] !== 'string' && typeof tree[b] === 'string') return -1
      a.localeCompare(b)
    })

    for (const fileorPath of filesOrPaths) {
      if (typeof tree[fileorPath] === 'string') {
        const li = document.createElement('li')
        const span = document.createElement('span')
        const a = document.createElement('a')
        const a2 = document.createElement('a')
        li.appendChild(span)
        li.appendChild(a)
        li.appendChild(a2)

        span.innerHTML = fileorPath

        a.innerHTML = '[preview]'
        a.setAttribute('data-schema', tree[fileorPath])
        a.addEventListener('click', updatePreview)

        a2.innerHTML = '[raw]'
        a2.href = tree[fileorPath]

        ul.appendChild(li)
      } else {
        const li = document.createElement('li')
        const subUl = document.createElement('ul')

        li.innerHTML = fileorPath + '/'

        _treeToHtml(subUl, tree[fileorPath])

        ul.appendChild(li)
        ul.appendChild(subUl)
      }
    }
  }

  const versions = Object.keys(tree).sort(rcompare)

  for (const version of versions) {
    const heading = document.createElement('h2')
    heading.innerHTML = version

    const files = document.createElement('ul')

    _treeToHtml(files, tree[version])

    el.appendChild(heading)
    el.appendChild(files)
  }
}

for (const schema of schemas) {
  const file = path.relative(dir, schema).split(path.sep)
  buildTree(tree, file)
}

treeToHtml(menu, tree)

console.log(tree)
